// WARNING: DO NOT EDIT!
// THIS FILE WAS GENERATED BY SHADOW-CLJS AND WILL BE OVERWRITTEN!

var ALL = {};
ALL["@patternfly/react-core/dist/esm/components/EmptyState"] = require("@patternfly/react-core/dist/esm/components/EmptyState");
ALL["@patternfly/react-charts/dist/esm/components/ChartAxis/ChartAxis"] = require("@patternfly/react-charts/dist/esm/components/ChartAxis/ChartAxis");
ALL["react-simple-code-editor"] = require("react-simple-code-editor");
ALL["@patternfly/react-core/dist/esm/components/Chip"] = require("@patternfly/react-core/dist/esm/components/Chip");
ALL["react-autocomplete-input"] = require("react-autocomplete-input");
ALL["@patternfly/react-core/dist/esm/components/Progress"] = require("@patternfly/react-core/dist/esm/components/Progress");
ALL["jszip-utils"] = require("jszip-utils");
ALL["@patternfly/react-core/dist/esm/components/Page"] = require("@patternfly/react-core/dist/esm/components/Page");
ALL["@patternfly/react-core/dist/esm/components/Alert"] = require("@patternfly/react-core/dist/esm/components/Alert");
ALL["@patternfly/react-core/dist/esm/components/Divider"] = require("@patternfly/react-core/dist/esm/components/Divider");
ALL["@patternfly/react-core/dist/esm/components/Dropdown"] = require("@patternfly/react-core/dist/esm/components/Dropdown");
ALL["@patternfly/react-charts/dist/esm/components/ChartBar/ChartBar"] = require("@patternfly/react-charts/dist/esm/components/ChartBar/ChartBar");
ALL["@patternfly/react-icons/dist/esm/icons/check-icon"] = require("@patternfly/react-icons/dist/esm/icons/check-icon");
ALL["@patternfly/react-core/dist/esm/components/ExpandableSection"] = require("@patternfly/react-core/dist/esm/components/ExpandableSection");
ALL["@patternfly/react-core/dist/esm/components/CodeBlock"] = require("@patternfly/react-core/dist/esm/components/CodeBlock");
ALL["pdfjs-dist/build/pdf"] = require("pdfjs-dist/build/pdf");
ALL["@patternfly/react-core/dist/esm/components/Tooltip"] = require("@patternfly/react-core/dist/esm/components/Tooltip");
ALL["@js-joda/core"] = require("@js-joda/core");
ALL["@patternfly/react-core/dist/esm/helpers/resizeObserver"] = require("@patternfly/react-core/dist/esm/helpers/resizeObserver");
ALL["@patternfly/react-core/dist/esm/components/InputGroup"] = require("@patternfly/react-core/dist/esm/components/InputGroup");
ALL["@patternfly/react-core/dist/esm/layouts/Grid"] = require("@patternfly/react-core/dist/esm/layouts/Grid");
ALL["jszip/vendor/FileSaver"] = require("jszip/vendor/FileSaver");
ALL["react-markdown"] = require("react-markdown");
ALL["@patternfly/react-core/dist/esm/components/FileUpload"] = require("@patternfly/react-core/dist/esm/components/FileUpload");
ALL["@patternfly/react-core/dist/esm/components/Panel"] = require("@patternfly/react-core/dist/esm/components/Panel");
ALL["@patternfly/react-icons/dist/esm/icons/times-icon"] = require("@patternfly/react-icons/dist/esm/icons/times-icon");
ALL["@patternfly/react-core/dist/esm/components/Button"] = require("@patternfly/react-core/dist/esm/components/Button");
ALL["zustand/middleware"] = require("zustand/middleware");
ALL["jszip"] = require("jszip");
ALL["@patternfly/react-core/dist/esm/components/Form"] = require("@patternfly/react-core/dist/esm/components/Form");
ALL["@patternfly/react-core/dist/esm/components/Badge"] = require("@patternfly/react-core/dist/esm/components/Badge");
ALL["@patternfly/react-core/dist/esm/layouts/Split"] = require("@patternfly/react-core/dist/esm/layouts/Split");
ALL["@patternfly/react-charts/dist/esm/components/ChartScatter/ChartScatter"] = require("@patternfly/react-charts/dist/esm/components/ChartScatter/ChartScatter");
ALL["@patternfly/react-charts/dist/esm/components/ChartLine/ChartLine"] = require("@patternfly/react-charts/dist/esm/components/ChartLine/ChartLine");
ALL["@patternfly/react-core/dist/esm/components/DataList"] = require("@patternfly/react-core/dist/esm/components/DataList");
ALL["transliteration/dist/browser/bundle.esm.min.js"] = require("transliteration/dist/browser/bundle.esm.min.js");
ALL["@patternfly/react-core/dist/esm/components/AboutModal"] = require("@patternfly/react-core/dist/esm/components/AboutModal");
ALL["bignumber.js"] = require("bignumber.js");
ALL["source/neckar.svg"] = require("source/neckar.svg");
ALL["print-js"] = require("print-js");
ALL["date-fns"] = require("date-fns");
ALL["@patternfly/react-core/dist/esm/components/Checkbox"] = require("@patternfly/react-core/dist/esm/components/Checkbox");
ALL["@patternfly/react-core/dist/esm/layouts/Flex"] = require("@patternfly/react-core/dist/esm/layouts/Flex");
ALL["@patternfly/react-core/dist/esm/components/Card"] = require("@patternfly/react-core/dist/esm/components/Card");
ALL["@patternfly/react-core/dist/esm/layouts/Stack"] = require("@patternfly/react-core/dist/esm/layouts/Stack");
ALL["@patternfly/react-core/dist/esm/components/DatePicker"] = require("@patternfly/react-core/dist/esm/components/DatePicker");
ALL["@patternfly/react-core/dist/esm/components/Tabs"] = require("@patternfly/react-core/dist/esm/components/Tabs");
ALL["react-dom"] = require("react-dom");
ALL["@patternfly/react-core/dist/esm/layouts/Bullseye"] = require("@patternfly/react-core/dist/esm/layouts/Bullseye");
ALL["@amplitude/analytics-browser"] = require("@amplitude/analytics-browser");
ALL["@patternfly/react-core/dist/esm/components/Popover"] = require("@patternfly/react-core/dist/esm/components/Popover");
ALL["@patternfly/react-core/dist/esm/components/Label"] = require("@patternfly/react-core/dist/esm/components/Label");
ALL["@patternfly/react-core/dist/esm/components/Backdrop"] = require("@patternfly/react-core/dist/esm/components/Backdrop");
ALL["@patternfly/react-core/dist/esm/components/Title"] = require("@patternfly/react-core/dist/esm/components/Title");
ALL["@patternfly/react-core/dist/esm/components/TextInputGroup"] = require("@patternfly/react-core/dist/esm/components/TextInputGroup");
ALL["react-hook-form"] = require("react-hook-form");
ALL["@patternfly/react-icons/dist/esm/createIcon"] = require("@patternfly/react-icons/dist/esm/createIcon");
ALL["react-dropzone"] = require("react-dropzone");
ALL["@patternfly/react-core/dist/esm/components/ClipboardCopy"] = require("@patternfly/react-core/dist/esm/components/ClipboardCopy");
ALL["@patternfly/react-icons/dist/esm/icons/info-icon"] = require("@patternfly/react-icons/dist/esm/icons/info-icon");
ALL["@patternfly/react-core/dist/esm/components/Menu"] = require("@patternfly/react-core/dist/esm/components/Menu");
ALL["@patternfly/react-charts/dist/esm/components/ChartTooltip/ChartTooltip"] = require("@patternfly/react-charts/dist/esm/components/ChartTooltip/ChartTooltip");
ALL["react-dom/server"] = require("react-dom/server");
ALL["@patternfly/react-core/dist/esm/components/NumberInput"] = require("@patternfly/react-core/dist/esm/components/NumberInput");
ALL["@patternfly/react-core/dist/esm/components/TextArea"] = require("@patternfly/react-core/dist/esm/components/TextArea");
ALL["pdfjs-dist/web/pdf_viewer"] = require("pdfjs-dist/web/pdf_viewer");
ALL["prismjs"] = require("prismjs");
ALL["@patternfly/react-core/dist/esm/components/LabelGroup"] = require("@patternfly/react-core/dist/esm/components/LabelGroup");
ALL["zustand"] = require("zustand");
ALL["remark-gfm"] = require("remark-gfm");
ALL["@patternfly/react-core/dist/esm/components/ChipGroup"] = require("@patternfly/react-core/dist/esm/components/ChipGroup");
ALL["copy-to-clipboard"] = require("copy-to-clipboard");
ALL["@patternfly/react-icons/dist/esm/icons/bars-icon"] = require("@patternfly/react-icons/dist/esm/icons/bars-icon");
ALL["@patternfly/react-core/dist/esm/components/CalendarMonth"] = require("@patternfly/react-core/dist/esm/components/CalendarMonth");
ALL["@patternfly/react-core/dist/esm/components/List"] = require("@patternfly/react-core/dist/esm/components/List");
ALL["@js-joda/locale_en-us"] = require("@js-joda/locale_en-us");
ALL["@patternfly/react-core/dist/esm/components/Spinner"] = require("@patternfly/react-core/dist/esm/components/Spinner");
ALL["keycloak-js"] = require("keycloak-js");
ALL["@patternfly/react-core/dist/esm/components/Switch"] = require("@patternfly/react-core/dist/esm/components/Switch");
ALL["@patternfly/react-core/dist/esm/components/FormSelect"] = require("@patternfly/react-core/dist/esm/components/FormSelect");
ALL["react-draggable"] = require("react-draggable");
ALL["@patternfly/react-charts/dist/esm/components/ChartLabel/ChartLabel"] = require("@patternfly/react-charts/dist/esm/components/ChartLabel/ChartLabel");
ALL["@patternfly/react-charts/dist/esm/components/ChartVoronoiContainer/ChartVoronoiContainer"] = require("@patternfly/react-charts/dist/esm/components/ChartVoronoiContainer/ChartVoronoiContainer");
ALL["@patternfly/react-core/dist/esm/components/Toolbar"] = require("@patternfly/react-core/dist/esm/components/Toolbar");
ALL["@patternfly/react-core/dist/esm/components/SearchInput"] = require("@patternfly/react-core/dist/esm/components/SearchInput");
ALL["@patternfly/react-core/dist/esm/components/Select"] = require("@patternfly/react-core/dist/esm/components/Select");
ALL["@patternfly/react-charts/dist/esm/components/Chart/Chart"] = require("@patternfly/react-charts/dist/esm/components/Chart/Chart");
ALL["@patternfly/react-core/dist/esm/components/HelperText"] = require("@patternfly/react-core/dist/esm/components/HelperText");
ALL["@patternfly/react-charts/dist/esm/components/ChartStack/ChartStack"] = require("@patternfly/react-charts/dist/esm/components/ChartStack/ChartStack");
ALL["@patternfly/react-core/dist/esm/components/ToggleGroup"] = require("@patternfly/react-core/dist/esm/components/ToggleGroup");
ALL["openseadragon"] = require("openseadragon");
ALL["@tanstack/react-table"] = require("@tanstack/react-table");
ALL["react"] = require("react");
ALL["@patternfly/react-icons/dist/esm/icons/search-icon"] = require("@patternfly/react-icons/dist/esm/icons/search-icon");
ALL["@patternfly/react-core/dist/esm/components/Text"] = require("@patternfly/react-core/dist/esm/components/Text");
ALL["@patternfly/react-core/dist/esm/components/Drawer"] = require("@patternfly/react-core/dist/esm/components/Drawer");
ALL["@patternfly/react-core/dist/esm/components/Avatar"] = require("@patternfly/react-core/dist/esm/components/Avatar");
ALL["@patternfly/react-core/dist/esm/components/Modal"] = require("@patternfly/react-core/dist/esm/components/Modal");
ALL["@patternfly/react-core/dist/esm/components/Nav"] = require("@patternfly/react-core/dist/esm/components/Nav");
ALL["@patternfly/react-charts/dist/esm/components/ChartGroup/ChartGroup"] = require("@patternfly/react-charts/dist/esm/components/ChartGroup/ChartGroup");
ALL["@patternfly/react-core/dist/esm/components/TextInput"] = require("@patternfly/react-core/dist/esm/components/TextInput");
ALL["@patternfly/react-core/dist/esm/components/AlertGroup"] = require("@patternfly/react-core/dist/esm/components/AlertGroup");
ALL["@patternfly/react-core/dist/esm/components/Truncate"] = require("@patternfly/react-core/dist/esm/components/Truncate");
ALL["@flows/js"] = require("@flows/js");
ALL["@patternfly/react-core/dist/esm/components/DescriptionList"] = require("@patternfly/react-core/dist/esm/components/DescriptionList");
global.shadow$bridge = function shadow$bridge(name) {
  var ret = ALL[name];

  if (ret === undefined) {
     throw new Error("Dependency: " + name + " not provided by external JS. Do you maybe need a recompile?");
  }

  return ret;
};

shadow$bridge.ALL = ALL;
